$color-hologram: rgb(0, 195, 255);
$color-space-blue: rgb(3, 4, 71);
$color-space-purple: rgb(68, 16, 70);




$font-alumni: 'Alumni Sans Inline One', cursive;;
$font-audiowide: 'Audiowide', cursive;
$font-bungee-inline: 'Bungee Inline', cursive;
$font-exo: 'Exo', sans-serif;
$font-gugi: 'Gugi', cursive;
$font-orbitron: 'Orbitron', sans-serif;
$font-quantico: 'Quantico', sans-serif;
$font-rampart: 'Rampart One', cursive;
$font-oswald: 'Oswald', sans-serif;
$font-righteous: 'Righteous', cursive;
$font-space-mono: 'Space Mono', monospace;
$font-syne: 'Syne', sans-serif;



// Breakpoints for different screen sizes used in responsive.scss
// Small Mobiles --- 0px - 360px
// Mobiles --- 361px - 768px
// Tabs --- 769px - 1024px
// Desktop --- 1025px - 1920px
// Large Screens --- Default CSS styles
$breakpoint-mobile-small: 360px;
$breakpoint-mobile: 768px;
$breakpoint-tab: 1024px;
$breakpoint-desktop: 1920px;