@keyframes bg-space-move{
    from{
        background-position: 720px 360px;
        // background-position: -100% -100%;
    }
}


// @keyframes bg-planet-move{
//     from{
//         // background-position: 1041px 0px;
//         background-position: 100% 0px;
//         // background-position: 1200px 0px;
//     }
// }
@keyframes bg-planet-move{
    from{
        background-position: 0% 0%;
    }
    to{
        background-position: 200% 0%;
    }
}


@keyframes show-screen{
    from{
        transform: scale(0);
    }
    to{
        transform: scale(1);
    }
}

