.dashboard{
    // background-color: aqua;
    width: 100%;
    height: 100vh;
    position: relative;

    &__btn--star{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__screen{
        width: 90%;
        height: 80%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        // max-width: 60rem;
    }

    &__card{
        width: 100%;
        height: 100%;
        // background-color: aqua;
    }


    &__profile{
        position: absolute;

        &--left{
            top: 1rem;
            left: 1rem;
        }
        &--right{
            top: 1rem;
            right: 1rem;
        }
    }


    &__main{
        // background-color: aqua;
        width: 90%;
        max-width: 40rem;
        aspect-ratio: 1;
        position: absolute;
        top: 50%;
        left: 50%;
        translate: -50% -50%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }
    
    &__logo{
        width: 100%;
        // display: flex;
        // flex-direction: column;
        // align-items: center; 
    }
}