*{
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    font-size: inherit;
    font-family: inherit;
    user-select: none;
}

body{
    box-sizing: border-box;
    font-size: 1.6rem;
    font-family: sans-serif;
    cursor: url(https://i.imgur.com/6wmyrkK.gif), auto;
}

html{
    font-size: 62.5%;
}

#root{
    width: 100%;
    height: 100vh;
    position: relative;
    // background-color: aqua;


    &::before{
        content: " ";
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        background-image: url(https://t4.ftcdn.net/jpg/04/93/69/75/360_F_493697539_Tn5BdOqahVEmjBxozRK5AqHrgw52D7sj.jpg);
        background-repeat: repeat;
        animation: bg-space-move 50s linear infinite;
        z-index: -10;
    }
}

ol, ul{
    list-style: none;
}

a{
    text-decoration: none;
}