.home{
    position: relative;
    width: 100%;
    height: 100vh;

    &__planet{
        position: absolute;

        &--main{
            width: 50%;
            max-width: 30rem;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &--top-left{
            width: 20%;
            max-width: 10rem;
            top: 10%;
            left: 10%;
            transform: translate(-50%, -50%);
        }
    }


    &__screen{
        width: 95%;
        max-width: 60rem;
        height: 95vh;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 2rem;

        display: flex;
        flex-direction: column;
        align-items: center;
    }
}