.glass{
    // background-color: rgba(0, 153, 255, 0.05);
    backdrop-filter: blur(15px);
    // border-top: 1px solid rgba(255, 255, 255, 0.2);
    // border-left: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 3px;
    box-shadow: 0px 0.5rem 1.5rem rgba(0, 0, 0, 0.2);



    &--white{
        background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.05));
        border: 2px solid;
        border-image: linear-gradient(to bottom right, rgb(2, 27, 94), transparent, rgb(62, 4, 80)) 10%;
        // border-image-outset: 1px;
        // border-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.5), transparent, rgba(255, 255, 255, 0.1)) 10%;
    }
    &--blue{
        background-image: linear-gradient(to bottom right, rgba(96, 197, 255, 0.4), rgba(96, 197, 255, 0.2));
        border: 2px solid;
        border-image: linear-gradient(to bottom right, rgb(2, 27, 94), transparent, rgb(62, 4, 80)) 10%;
        // border-image-outset: 1px;
        // border-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.5), transparent, rgba(255, 255, 255, 0.1)) 10%;
    }
    
    &__screen{
        width: 90%;
        height: 90vh;
    
        border-radius: 1rem;
        padding: 2rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);



    }

    &__btn{
        padding: 1rem 2rem;
    }
}