.selector{
    display: flex;
    flex-direction: column;


    &__heading{
        margin-bottom: 1.5rem;
        width: 90%;
        font-size: 2rem;
        text-align: left;
    }
    &__option-group{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
        width: 100%;
        // height: 100%;
    }

    &__option{
        width: 100%;
        // height: 100px;
        // background-color: aqua;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 2px solid aqua;
        padding: 1rem;
        border-radius: 1rem;

        &__image{
            width: 70%;
            aspect-ratio: 1;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            margin: 1rem 0rem;
        }

        &__heading{
            width: 100%;
            text-align: center;
            text-transform: capitalize;
            margin-bottom: 1.5rem;
        }
        &__text{
            width: 100%;
            text-align: justify;
            margin-bottom: 0.5rem;
            font-size: 1.4rem;
        }
    }

    &__hr{
        width: 100%;
        margin: 2rem 0rem;
        height: 1px;
        background-color: aqua;
        border: none;
    }
}