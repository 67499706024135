.hologram{


    &__screen{
        padding: 2.5rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1);
        outline: 6px solid rgba(lighten($color-hologram,30), 0.7);
        outline-offset: -1.5rem;
        outline-style: double;
        overflow-y: scroll;
        animation: show-screen 0.3s linear;
        display: flex;
        flex-direction: column;
        align-items: center;


        &::-webkit-scrollbar{
            display: none;
        }
    }

    &__btn-hide{
        position: absolute;
        right: 0rem;
        top: 0rem;
        background-color: rgba($color-hologram, 0.9);
        width: 10%;
        aspect-ratio: 1;
        max-width: 4rem;
        border: none;
        // backdrop-filter: blur(10px);
        border-radius: 2rem;
        color: white;
    }

    &__btn{
        text-transform: capitalize;
        padding: 1rem 2rem;
        border-radius: 3px;
        cursor: pointer;
        border: none;
        margin: 1rem;

        &--focus{
            background-color: rgba(lighten($color-hologram,50), 0.7);
            color: rgba(darken($color-hologram,40), 0.7);
        }
        &--dull{
            border: 2px solid;
            border-color: rgba(lighten($color-hologram,50), 0.7);
            color: rgba(lighten($color-hologram,50), 0.7);
        }
    }

    &__text{
        width: 90%;
        height: fit-content;
        color: lighten($color-hologram, 50);
        letter-spacing: 0.5px;
        line-height: 2rem;
        text-align: justify;
        margin: 0.5rem;
    }

    &__textbox{
        width: 90%;
        padding: 1rem 1.5rem;
        margin: 0.5rem 0rem 1.5rem;
        background-color: transparent;
        border: 2px solid transparent;
        box-shadow: inset 1px 1px 3px rgb(103, 103, 255), inset -1px -1px 3px rgb(0, 64, 202);
        outline: transparent;
        border-radius: 0.5rem;
        transition: 0.2s linear;
        color: aqua;
        animation: textbox-visible 0.5s ease-in-out;

        &::placeholder{
            color: aqua;
            text-transform: capitalize;
            opacity: 0.5;
        }
        
        &:hover{
            box-shadow: inset -1px -1px 3px rgb(85, 85, 255), inset 1px 1px 3px rgb(0, 0, 138);
        }
        &:focus{
            box-shadow: inset -1px -1px 3px rgb(85, 85, 255), inset 1px 1px 3px rgb(0, 0, 138);
            outline: 2px solid rgb(228, 228, 255);
            outline-offset: 1px;
        }
        &:not(:placeholder-shown){
            box-shadow: inset -1px -1px 3px rgb(85, 85, 255), inset 1px 1px 3px rgb(0, 0, 138);
        }
    }

}


@keyframes textbox-visible{
    from{
        opacity: 0;
        width: 0;
    }
    to{
        opacity: 1;
        width: 90%;
    }
}



