.btn {
  text-transform: capitalize;
  cursor: pointer;
  font-family: $font-righteous;
  outline: transparent;
  transition: 0.3s linear;

  &--space {
    font-size: 2rem;
    padding: 1.5rem 3.5rem;
    background-image: linear-gradient(
        217deg,
        rgba(lighten($color-space-purple, 15), 0.8),
        rgba(lighten($color-space-purple, 25), 0) 70.71%
      ),
      linear-gradient(
        127deg,
        rgba(lighten($color-space-blue, 25), 0.8),
        rgba(0, 255, 0, 0) 100.71%
      ),
      linear-gradient(
        336deg,
        rgba(rgb(255, 0, 64), 0.7),
        rgba(0, 0, 255, 0) 85.71%
      );
    background-color: rgb(112, 112, 112);
    background-blend-mode: overlay;
    border-radius: 50rem;
    color: rgb(204, 204, 204);
    letter-spacing: 2px;
    border: 3px solid rgb(162, 0, 255);
  }

  &--3d {
    &:hover {
      box-shadow: inset 2px 2px 5px rgba(255, 255, 255, 0.466),
        inset -2px -2px 5px rgba(0, 0, 0, 0.9);
    }
    &:active {
      box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.9),
        inset -2px -2px 5px rgba(255, 255, 255, 0.9);
    }
  }

  &--ui {
     }
}
