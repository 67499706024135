.logo {
  display: flex;
  flex-direction: column;
  align-items: center;

  font-family: $font-bungee-inline;
  font-family: $font-rampart;
  // font-family: $font-alumni;
  line-height: 8rem;
  font-size: 7rem;
    $color: rgb(0, 132, 255);
  color: transparent;
  text-transform: uppercase;
    text-shadow: 0px 0px 10px $color,
    0px 0px 25px $color,
    0px 0px 50px $color;
  
  
  &__part{
    background-image: linear-gradient(to bottom right, rgb(255, 63, 63), rgb(255, 163, 163));
    background-clip: text;

}
}
