.quiz{

    width: 100%;
    // max-width: 60rem;
    height: 100%;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;



    &__question{
        padding: 1rem;
        text-align: center;
        // background-color: rgba(0, 102, 255, 0.6);
        // border-radius: 500rem;
        color: rgb(202, 244, 255);
        line-height: 2.5rem;
        letter-spacing: 0.5px;
        word-spacing: 2px;
        margin: 3rem 1rem;
    }

    &__options{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 75%;
        min-width: fit-content;
        margin: auto;
        gap: 1rem;
    }

    &__option{
        min-width: 50%;
        width: 75%;
        // width: fit-content;
        padding: 1.5rem 2rem;
        border-radius: 1rem;
        border: none;
        cursor: pointer;
        color: white;
        transition: 0.1s ease-in-out;

        background-color: rgba(255, 255, 255, 0.0);
        border: 3px solid rgba(255, 255, 255, 0.0);
        
        &:hover{
            background-color: rgba(255, 255, 255, 0.15);
        }
        
        
        &--selected{
            background-color: rgba(255, 217, 0, 0.15) !important;
            $color: rgba(255, 217, 0, 1);
            border: 3px solid $color;
        }
        &--correct{
            background-color: rgba(51, 255, 0, 0.15) !important;
            $color: rgba(51, 255, 0, 1);
            border: 3px solid $color;
        }
        &--incorrect{
            background-color: rgba(255, 0, 0, 0.15) !important;
            $color: rgba(255, 0, 0, 1);
            border: 3px solid $color;
        }
    }


    
}