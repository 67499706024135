.game-selector{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    // padding: 1rem;
}
.level-selector{
    width: 100%;
    height: 70%;
    max-width: 70rem;
    // background-color: rgba(240, 248, 255, 0.562);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    // padding: 1rem;
}